<template>
  <div>
    <BaseCardView
      :loading="spendChartLoading"
      card-padding="0px"
      box-shadow="none"
      title="Spending"
      loading-height="25vh"
    >
      <template #content>
        <CustomersSpendChart :data="spendChartData" :show-top-date="true" />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import OverviewAPI from "@/services/api/OverviewAPI";
import CustomersSpendChart from "./CustomersSpendChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { BaseCardView, CustomersSpendChart },
  props: {
    selectedCurrency: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const spendChartData = ref({});
    const spendChartLoading = ref(false);

    const filters = computed(() => {
      return store.getters.filters;
    });

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const getSpendChartData = () => {
      spendChartLoading.value = true;
      spendChartData.value = [];

      //Abort Controller
      const abortController = new AbortController();

      OverviewAPI.getSpendChartData(
        {
          ...filters.value,
          tenant_transaction_currency_codes: [
            props.selectedCurrency?.tenant_customers_currency_code,
          ],
        },
        timeFilters.value?.periodFilter ?? 30,
        {
          signal: abortController.signal, //Assign Signal
        }
      )
        .then((res) => {
          spendChartData.value = [...(res.data?.tenant_customers_spends ?? [])];
          spendChartLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendChartData.value = [];
            spendChartLoading.value = false;
            console.error("error: ", error);
          }
        });

      // Add cancel token to list of cancel tokens
      store.commit("CURRENCY_ADD_CANCEL_TOKEN", abortController);
    };

    function fetch() {
      //[1] Cancel all pending requests if exists
      store.dispatch("CURRENCY_CANCEL_PENDING_REQUESTS");

      //[2] new fetch
      getSpendChartData();
    }

    watch(
      () => props.selectedCurrency,
      (currentCurrency) => {
        if (Object.keys(currentCurrency).length !== 0) {
          fetch();
        }
      },
      {
        immediate: true,
      }
    );

    return { spendChartData, spendChartLoading };
  },
};
</script>

<style lang="scss" scoped></style>
