<template>
  <div class="d-flex flex-column h-100">
    <div
      v-show="(currenciesList ?? []).length > 0"
      class="py-3 col-12 col-md-6 px-4"
    >
      <BaseSearchAction
        id="search-currency-input"
        placeholder="Search by currency"
        @updated-search="(val) => (searchQry = val)"
      />
    </div>

    <div class="d-flex flex-column overflow-hidden hide-scrollbar flex-grow-1">
      <div
        class="
          d-flex
          flex-column flex-lg-row
          align-items-start
          justify-content-between
          h-100
          overflow-auto overflow-lg-hidden
          gap-lg-0 gap-3
        "
      >
        <div class="col-12 col-lg-4 hide-scrollbar pb-5 overflow-auto h-100">
          <AllCurrenciesScrollList
            :search-qry="searchQry"
            :currencies-list="currenciesList"
            :loading="currenciesListLoading"
          />
        </div>

        <div
          class="
            col-12 col-lg-8
            pb-5
            ps-lg-3
            hide-scrollbar
            overflow-auto
            h-100
            pe-4
            ps-4 ps-lg-0
          "
        >
          <SpecificCurrencyDetails :loading="currenciesListLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpecificCurrencyDetails from "../components/SpecificCurrencyDetails.vue";
import AllCurrenciesScrollList from "../components/AllCurrenciesScrollList.vue";
import { useStore } from "vuex";
import { ref, computed, onUnmounted, watch, onMounted } from "vue";
import BaseSearchAction from "@/components/helpers/BaseSearchAction.vue";
import OverviewAPI from "@/services/api/OverviewAPI";

export default {
  components: {
    BaseSearchAction,
    AllCurrenciesScrollList,
    SpecificCurrencyDetails,
  },
  setup() {
    const store = useStore();
    const searchQry = ref("");

    const currenciesListLoading = ref(false);
    const currenciesList = ref([]);

    const baseAppliedFilters = computed(() => {
      return store.state.overViewStore?.appliedFilters;
    });
    const multiCurrencyFilters = computed(() => {
      return store.getters.multiCurrencyFilters;
    });
    const filters = computed(() => {
      let reqFilters = { ...store.getters.filters };

      //Remove default currency
      delete reqFilters.tenant_transaction_currency_codes;

      //Add Multi currency filter
      if ("tenant_transaction_currency_codes" in multiCurrencyFilters.value)
        reqFilters.tenant_transaction_currency_codes =
          multiCurrencyFilters.value.tenant_transaction_currency_codes;

      return reqFilters;
    });

    const normalizeItemsData = (dataList) => {
      if ((dataList ?? []).length === 0) return [];

      let data = [];
      dataList.forEach((item) => {
        data.push({
          ...item,
          tx_count: item?.tenant_customers_transactions_count,
          users_count: item?.tenant_customers_count,
          label: item?.tenant_customers_currency_code,
          total_amount: item?.tenant_customers_total_spend,
          avg_amount: item?.tenant_customers_avg_spend,
          rel_total_amount:
            item?.comparison_period_tenant_customers_total_spend,
          rel_avg_amount: item?.comparison_period_tenant_customers_avg_spend,
          rel_users_count: item?.comparison_period_tenant_customers_count,
        });
      });

      return data;
    };

    const getSpendPerCurrencyData = () => {
      currenciesListLoading.value = true;
      currenciesList.value = [];

      const queryFilters = { ...filters.value };

      OverviewAPI.getSpendPerCurrencyData(queryFilters)
        .then((res) => {
          currenciesList.value = normalizeItemsData(
            res.data?.tenant_customers_spends ?? []
          );
          currenciesListLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            currenciesList.value = [];
            currenciesListLoading.value = false;
            console.error("error: ", error);
          }
        });
    };
    const fetch = () => {
      getSpendPerCurrencyData();
    };

    //watchers
    watch(
      () => filters.value,
      (currFilters, prevFilters) => {
        if (prevFilters?.tenant_transaction_from_date) {
          //[1] Abort all previous pending requests
          store.dispatch("CANCEL_PENDING_REQUESTS");

          //[2] Abort Details Request
          store.dispatch("CURRENCY_CANCEL_PENDING_REQUESTS", true);

          //[2] Fetch new requests
          fetch();
        }
      },
      {
        deep: true,
      }
    );

    //hooks
    onMounted(() => {
      fetch();
    });
    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });

    return {
      searchQry,
      currenciesListLoading,
      currenciesList,
      filters,
      multiCurrencyFilters,
      baseAppliedFilters,
    };
  },
};
</script>

<style lang="scss" scoped></style>
