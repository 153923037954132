<template>
  <div>
    <ActionButton
      type="button"
      label="View more Details"
      class="btn-primary"
      @click="
        $router.push({
          name: 'SpecificCurrency',
          params: {
            currencyId: selectedCurrency?.tenant_customers_currency_code,
          },
        })
      "
    />
  </div>
</template>

<script>
import ActionButton from "./helpers/ActionButton.vue";
export default {
  components: { ActionButton },
  props: {
    selectedCurrency: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
