<template>
  <BaseCardView id="SpecificCurrencyDetails" :loading="loading">
    <template #content>
      <div
        v-if="Object.keys(selectedCurrency).length !== 0"
        class="d-flex flex-column gap-4 py-2 px-1"
      >
        <!--Heading-->
        <SpecificCurrencyHeading
          class="mb-2"
          :header="selectedCurrency?.tenant_customers_currency_code"
        />
        <!--Heading-->

        <!--Overview-->
        <SpecificCurrencyOverview :selected-currency="selectedCurrency" />
        <!--Overview-->

        <div class="hr-gray my-4"></div>

        <!--Spending-->
        <SpecificCurrencySpending :selected-currency="selectedCurrency" />
        <!--Spending-->

        <!--Details Btn-->
        <SpecificCurrencyActionBtn :selected-currency="selectedCurrency" />
        <!--Details Btn-->
      </div>

      <BaseEmptyState v-else min-height="40vh" icon-size="100px" />
    </template>
  </BaseCardView>
</template>

<script>
import SpecificCurrencyHeading from "./SpecificCurrencyHeading.vue";
import SpecificCurrencyActionBtn from "./SpecificCurrencyActionBtn.vue";
import SpecificCurrencySpending from "./SpecificCurrencySpending.vue";
import SpecificCurrencyOverview from "./SpecificCurrencyOverview.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    BaseCardView,
    BaseEmptyState,
    SpecificCurrencyOverview,
    SpecificCurrencySpending,
    SpecificCurrencyActionBtn,
    SpecificCurrencyHeading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const selectedCurrency = computed(
      () => store.state.allCurrenciesStore.selectedCurrency ?? {}
    );

    return { selectedCurrency };
  },
};
</script>

<style lang="scss" scoped></style>
