<template>
  <h2 class="currency-header">{{ header }}</h2>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.currency-header {
  font-weight: 600;
}
</style>
