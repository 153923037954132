<template>
  <div
    class="d-flex flex-column justify-content-start gap-3 align-items-stretch"
  >
    <template v-if="!loading">
      <template v-if="sortedList.length !== 0">
        <div class="ps-2 ps-4 bg-white">
          <BaseViewInfoText :only-fetch-on-mounted="true" />
        </div>
        <DetailedSpendTypesFilters
          v-model="sortOption"
          class="py-3 top-sticky bg-white ps-4"
        />
        <AllCurrenciesScrollListItems
          class="ps-4 pe-4 pe-lg-0"
          :list-items="sortedList"
          @on-select="onSelect"
        />
      </template>

      <template v-else>
        <BaseEmptyState min-height="45vh" icon-size="100px" />
      </template>
    </template>

    <BaseLoadingCard v-else class="my-4 pt-2" />
  </div>
</template>

<script>
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseLoadingCard from "./helpers/BaseLoadingCard.vue";
import AllCurrenciesScrollListItems from "./AllCurrenciesScrollListItems.vue";
import { reactive, ref, computed, onMounted, watch } from "vue";
import DetailedSpendTypesFilters from "./DetailedSpendTypesFilters.vue";
import BaseViewInfoText from "./helpers/BaseViewInfoText.vue";
import { useStore } from "vuex";
export default {
  components: {
    BaseViewInfoText,
    DetailedSpendTypesFilters,
    AllCurrenciesScrollListItems,
    BaseLoadingCard,
    BaseEmptyState,
  },
  props: {
    searchQry: {
      type: String,
      default: "",
    },
    currenciesList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const sortOption = ref(0);
    const sortOptions = reactive({
      spend: 0,
      customers: 1,
    });

    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );

    const dataList = computed(() => {
      return props.currenciesList ?? [];
    });

    const sortedList = computed(() => {
      const list = dataList.value;
      let sortedList = [];
      // exit if empty
      if (list.length === 0) return [];

      //[1] sort the list
      sortedList = list.sort((a, b) => {
        let res = 0;

        switch (sortOption.value) {
          case sortOptions.spend:
            res = Math.abs(getSpend(b)) - Math.abs(getSpend(a));
            break;

          case `reverse-${sortOptions.spend}`:
            res = Math.abs(getSpend(a)) - Math.abs(getSpend(b));
            break;

          case sortOptions.customers:
            if (a.users_count || b.users_count)
              res =
                Math.abs(b?.users_count ?? 0) - Math.abs(a?.users_count ?? 0);
            break;

          case `reverse-${sortOptions.customers}`:
            if (a.users_count || b.users_count)
              res =
                Math.abs(a?.users_count ?? 0) - Math.abs(b?.users_count ?? 0);
            break;

          default:
            break;
        }

        return res;
      });

      //[2] apply search on the list
      if (props.searchQry) {
        sortedList = sortedList.filter((item) =>
          (item?.label ?? "")
            .toLowerCase()
            .includes(props.searchQry.toLowerCase())
        );
      }

      return sortedList;
    });

    function getSpend(option) {
      if (metricFilters.value.key === "average") {
        return option?.avg_amount ?? 0;
      }

      return option?.total_amount ?? 0;
    }

    function onSelect(item) {
      store.commit("updateSelectedCurrency", item);
    }

    onMounted(() => {
      store.commit("updateSelectedCurrency", sortedList.value?.[0] ?? {});
    });

    watch(
      sortedList,
      (currList) => {
        store.commit("updateSelectedCurrency", currList?.[0] ?? {});
      },
      { deep: true }
    );

    return { sortOption, sortedList, onSelect };
  },
};
</script>

<style lang="scss" scoped></style>
