<template>
  <div
    class="d-flex flex-column justify-content-start gap-3 align-items-stretch"
  >
    <div v-for="item in listItems" :key="item.tenant_customers_currency_code">
      <AllCurrenciesScrollListCardItem
        :data="item"
        class="cp box"
        :class="{
          selected: isSelected(item),
        }"
        @click="$emit('on-select', item)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import AllCurrenciesScrollListCardItem from "./AllCurrenciesScrollListCardItem.vue";
import { useStore } from "vuex";
export default {
  components: { AllCurrenciesScrollListCardItem },
  props: {
    listItems: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["on-select"],
  setup() {
    const store = useStore();

    const selectedCurrency = computed(
      () => store.state.allCurrenciesStore.selectedCurrency ?? {}
    );

    function isSelected(item) {
      return (
        selectedCurrency.value?.tenant_customers_currency_code ===
        item?.tenant_customers_currency_code
      );
    }

    return { selectedCurrency, isSelected };
  },
};
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid white;
  transition: var(--d-ease);
}
.selected {
  border: 1px solid var(--green);
}
</style>
